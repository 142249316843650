import { API } from 'common';

const getCategories = () => API.get(`service/category/list`);

const getServices = () => API.get(`service/list`);

const getStaffs = () => API.get(`staff/list`);

const shopInfo = () => API.get('shop/detail');

const allBannerByShop = () => API.get('shop/info');

const staffShopsByPhone = (phone) => API.get('staff/sign-in-by-phone/' + phone);

const shopApis = {
  getStaffs,
  getCategories,
  getServices,
  shopInfo,
  allBannerByShop,
  staffShopsByPhone,
};

export default shopApis;
