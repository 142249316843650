
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import apis from './apis';
import { setHeader } from 'common';

export const getCategories = function* () {
  try {
    const { cate, ser } = yield all({ cate: call(apis.getCategories), ser: call(apis.getServices) });

    const categoriesRes = cate?.data?.payload;
    const servicesRes = ser?.data?.payload;
    if (categoriesRes && servicesRes) {
      const data = categoriesRes.map((o) => ({
        id: o.id?.toString(),
        name: o.categoryName,
        services: servicesRes.filter(s => s.catId === o.id).map(s => ({
          cateId: o.id?.toString(),
          id: s.id?.toString(),
          image: s.urlImage,
          name: s.serviceName,
          duration: s.duration,
          price: s.price,
        })),
      }));
      yield put(actions.data.category.success(data));
    }
  } catch (error) { }
};

const getStaffs = function* () {
  try {
    const res = yield call(apis.getStaffs);
    const staffRes = res?.data?.payload;
    if (staffRes) {
      const data = staffRes.map(o => ({
        avatar: o.urlImage,
        extraData: o,
        id: o.id?.toString(),
        name: o.firstName,
      }));
      yield put(actions.data.staff.success(data));
    }
  } catch (error) { }
};

export const initShopData = function* () {
  yield all([getCategories(), getStaffs()]);
};

const getShopDetailInfo = function* () {
  try {
    const res = yield call(apis.shopInfo);
    const resData = res.data.payload;
    if (resData) {
      yield put(actions.data.shopInfo.success(resData));
    }
  } catch (error) { }
  finally {
    yield put(actions.setShopLoading(false));
  }
};
const getAllBanner = function* () {
  try {
    const res = yield call(apis.allBannerByShop);
    const resData = res.data.payload;
    if (resData) {
      yield put(actions.data.allBanner.success(resData));
    }
  } catch (error) { }
};

const getStaffShopsByPhone = function* ({ payload }) {
  try {
    const res = yield call(apis.staffShopsByPhone, payload);;
    const resData = res.data.payload;
    if (resData) {
      yield put(actions.data.staffShopsByPhone.success(resData));
    }
  } catch (error) { }
}

const initWithApiKey = function* ({ payload }) {
  const key = payload;
  setHeader(key);
  yield put(actions.setShopLoading(true));
  yield delay(200);
  yield all([getShopDetailInfo(), getCategories(), getStaffs()]);
};


export default function* shopServiceSagas() {
  yield takeLatest(actions.init, initShopData);
  yield takeLatest(actions.initWithApiKey, initWithApiKey);
  yield takeLatest(actions.data.shopInfo.fetch, getShopDetailInfo);
  yield takeLatest(actions.data.allBanner.fetch, getAllBanner);
  yield takeLatest(actions.data.staffShopsByPhone.fetch, getStaffShopsByPhone);
}
