import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import Cookies from 'js-cookie';

const _scheduleBooking = {
  ['Sun']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Sat']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Fri']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Thu']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Wed']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Tue']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
  ['Mon']: {
    enable: true,
    endTime: '21:00',
    startTime: '08:00',
  },
};

const initialState = {
  staffs: [],
  categories: [],
  allServices: [],
  customers: [],
  shopLoading: true,
  shopInfo: null,
  scheduleBooking: _scheduleBooking,
  allBanner: [],
  staffShopsByPhone: [],
  currentSalon: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.data.staffShopsByPhone.success, (state, { payload }) => {
        const currentKey = Cookies.get(process.env.REACT_APP_API_X_KEY);
        state.currentSalon = payload.find(o => o.apiKey === currentKey) || null;
        state.staffShopsByPhone = payload;
      })
      .addCase(actions.data.shopInfo.success, (state, { payload }) => {
        state.shopInfo = payload;
      })
      .addCase(actions.data.allBanner.success, (state, { payload }) => {
        state.allBanner = payload;
      })
      .addCase(actions.setShopLoading, (state, { payload }) => {
        state.shopLoading = payload;
      })
      .addCase(actions.data.category.success, (state, { payload }) => {
        state.categories = payload;
        const services = [];
        state.categories.map(cate => {
          cate.services.map(s => {
            services.push({
              ...s,
              cateId: cate.id,
            });
          });
        });
        state.allServices = services;
      })
      .addCase(actions.data.staff.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.data.customer.success, (state, { payload }) => {
        state.customers = payload;
      })
      ;
  },
});

const shopServiceReducer = Slice.reducer;
export default shopServiceReducer;
