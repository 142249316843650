import { API, getDataCookie, initHeader, isAuthenticated, logOut, setCookieAuthenticate, setHeader } from 'common';
import Loader from 'components/Loader';
import Page from 'components/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { uuid } from 'uuidv4';
import BSUploadFile from '../BSUploadFile';
import clock_icon from '../icons/clock.svg';
import global_icon from '../icons/global-blue.svg';
import playIcon from '../icons/play.svg';
import upload_icon from '../icons/upload.svg';
import ModalViewGallery from '../ModalViewGallery';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import galleryActions from 'store/gallery/actions';
import { galleryUIActions } from 'store/gallery/reducers';
import gallerySelectors from 'store/gallery/selectors';
import moment from 'moment';
import { first, last, sortBy } from 'lodash';
import ReactSelect from 'react-select';
import shopSelectors from 'store/shop/selectors';
import Cookies from 'js-cookie';
import ModalConfirm from 'widgets/ModalConfirm';
import { toastRef } from 'components/Toast';

const genTempImage = () => ({
  type: 'image',
  id: uuid(),
  source: 'https://s3-alpha-sig.figma.com/img/3f07/843d/b89c398d8930638c88124486b26b89fb?Expires=1729468800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Pg7bOSxbH9TYJMaU4kbmtF2HhykaRHpg52arNM7r2l260yneBuD4zmaFKOn~~NNpAmVDCdweuXZfINXFGgbFU~w2owa08Tk4dQeOuemUcemihsUkkZ3b-mdtq7K-VblUNkYiMu08GzzqFqK5TgHL3TR0zTTBKsflG~oePOS68kvwYkLGeh9lrjHJzudG6zkR-W4lQYHd~-k8M9CWXlZD-DQxeV34w8ngR1hQY0j~aIM3hgyAHha7RYwxLQG2LQ7H2sJDnebElC89ixD7TUXyOq68l0AERIikKKlpAp-K~-hYotoe5-zdGtv5Ysf7KQ6FQebz2qZsiAbWen06sNfjpw__',
});

const genTempVideo = () => ({
  type: 'video',
  id: uuid(),
  source: 'https://pic.pikbest.com/00/87/40/80R888piCZwz.mp4',
});

const filesPending = [
  genTempImage(), genTempVideo(), genTempImage(),
  genTempVideo(), genTempImage(), genTempImage(),
  genTempImage(), genTempVideo(), genTempVideo(),
];
const publishPending = [
  genTempImage(), genTempVideo(), genTempImage(),
  genTempImage(), genTempVideo()
];

const StaffInformation = () => {
  const dispatch = useAppDispatch();
  const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
  const modalViewGalleryRef = useRef(null);
  const modalUploadRef = useRef(null);
  const modalConfirm = useRef(null);
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();
  const shops = useAppSelector(shopSelectors.staffShopsByPhone);
  const currentSalon = useAppSelector(shopSelectors.currentSalon);
  const onChooseSalon = async (select) => {
    const salon = select.value;
    if (currentSalon.id === salon.id) return;
    const staffPhone = Cookies.get(process.env.REACT_APP_COOKIE_KEY_PHONE);
    if (!salon.id || !staffPhone) return;
    if (!modalConfirm.current) return;
    setLoading(true);
    try {
      const response = await API.post('staff/generateOtp', {
        payload: {
          phone: staffPhone,
          shopId: salon.id
        },
      });
      const staffId = response.data.payload.staffId;
      if (staffId) {
        modalConfirm.current.open({
          title: 'Switch Salon',
          message: 'Are you want to switch to ' + salon.companyName + ' ?',
        }, async () => {
          setLoading(true);
          try {
            let response = await API.post('staff/verifyOtp', {
              payload: {
                staffId: staffId,
                verifyCode: '1234',
                shopId: salon.id
              },
            });
            if (response.status === 200) {
              const { payload } = response.data;
              const salon = payload.shop;
              const staff = payload.staff;
              if (payload != 0) {
                setHeader(salon.apiKey);

                setCookieAuthenticate(
                  staff.id,
                  parseInt(process.env.REACT_APP_EXPIRES_COOKIE)
                );
                // check is staff or manager
                var is_manager = 0;
                if (staff.positionName && staff.positionName === 'Admin') {
                  is_manager = 1;
                }
                Cookies.set(process.env.REACT_APP_COOKIE_KEY_PHONE, staffPhone, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
                Cookies.set(process.env.REACT_APP_COOKIE_KEY_ISMANAGER, is_manager, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
                initHeader();
                localStorage.setItem(
                  'infor_staff',
                  JSON.stringify(staff)
                );
                localStorage.setItem(
                  'info_salon',
                  JSON.stringify(salon)
                );

                window.location.href = is_manager ? 'overview' : '/';
              }
            }
          } catch (error) {
            if (toastRef.current) toastRef.current.show(error?.response?.data?.message || 'An error occurred, please try again!');
          } finally {
            setLoading(false);
          }
        })
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(galleryUIActions.staffInit());
  }, []);

  const handleUploadFile = async (_staffId, file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      const res = await API.post(`/gallery/upload/${_staffId}`, formData);
      if (res.data.payload) {
        dispatch(galleryActions.staff.getPendingGallery.fetch());
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }
  const viewFile = (file) => modalViewGalleryRef.current && modalViewGalleryRef.current.open(file);
  const onUploadFile = () => {
    if (modalUploadRef.current) modalUploadRef.current.open(staffId);
  }
  const redirectLogin = () => {
    if (!isAuthenticated()) {
      history.push('/login');
    }
  };
  const handleLogout = () => {
    logOut(redirectLogin);
  }
  const StickFooter = () => (
    <Row style={{ padding: '4px 15px', background: '#fff', paddingBottom: '8px' }}>
      <div style={{ margin: '0 15px', width: '100%', display: 'flex', gap: 8 }}>
        <Button onClick={handleLogout} type='button' className="w-100" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem', background: '#eee', border: 'none' }} >Logout</Button>
      </div>
    </Row>
  );
  return (
    <>
      {loading && <Loader />}
      <Page
        title={'Account'}
        containerClass={'staff-information-content-kit'}
        StickFooter={StickFooter}
      >
        <div className="salon-switch-container">
          <div className="label-updated">Your Salons:</div>
          <ReactSelect
            value={currentSalon ? { value: currentSalon, label: currentSalon.companyName } : null}
            onChange={onChooseSalon}
            options={shops.map((i) => ({
              value: i,
              label: i.companyName,
            }))}
            className="input-group-alternative"
            isSearchable={false}
            placeholder="Salon"
            menuPlacement={'bottom'}
            styles={{
              control: (base) => ({
                ...base,
                height: 46,
                minHeight: 46,
                border: '1px solid #adb5bd',
                color: '#adb5bd',
              }),
            }}
            name="salon"
          />
        </div>
        <div className="staff-images-container">
          <div className="top-content-container">
            <div className="title-section">Images</div>
            <button type='button' className="btn-upload" onClick={onUploadFile}>
              <img src={upload_icon} alt="upload_icon" className='upload_icon' />
              <span>Upload</span>
            </button>
          </div>

          <div className="image-type-groups">
            <div className="image-type-item pending-tab">
              <div className="label-image-group-container">
                <img src={clock_icon} alt="clock_icon" className='label-icon' />
                <span>Pending approval</span>
              </div>
              <Files staffId={staffId} onViewDetail={viewFile} />
            </div>
            <div className="image-type-item publish-tab">
              <div className="label-image-group-container">
                <img src={global_icon} alt="global_icon" className='label-icon' />
                <span>Public</span>
              </div>
              <Files staffId={staffId} onViewDetail={viewFile} pending={false} />
            </div>
          </div>
        </div>
        <ModalViewGallery ref={modalViewGalleryRef} />
        <BSUploadFile ref={modalUploadRef} onUploadFile={handleUploadFile} />
        <ModalConfirm ref={modalConfirm} />
      </Page>
    </>
  )
}

export default StaffInformation;
function checkURL(url) {
  return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}
const Files = ({ staffId, onViewDetail, pending = true }) => {
  const list = useAppSelector(pending ? gallerySelectors.staff.pendingGallery : gallerySelectors.staff.approvedGallery);
  const data = useMemo(() => {
    const galleries = (list.find(o => o.staffId?.toString() === staffId)?.details || []);
    const dates = [];
    const files = galleries?.map(s => {
      dates.push(moment(s.createdDate, 'MM-DD-YYYY'));
      return ({
        type: checkURL(s?.urlImage || '') ? 'image' : 'video',
        id: s.galleryId,
        source: s.urlImage || '',
      });
    });
    const datesSort = sortBy(dates, o => o);
    const labels = datesSort.map((s, i) => i === datesSort.length - 1 ? s.format('MM-DD-YYYY') : s.format('MM-DD'));
    return ({
      files,
      label: labels.length > 1 ? `${first(labels) || '--'} to ${last(labels) || '--'}` : `${last(labels) || '-- -- ----'}`,
    })
  }, [list, staffId]);

  return <>
    <div className='group-image-container'>
      <div className='label-updated'>Uploaded on {data.label}</div>
      <div className="image-type-images-list">
        {data?.files?.map(file => (
          <button onClick={() => onViewDetail(file)} type='button' key={file.id} className='media-item'>
            {file.type === 'video' ?
              <video src={file.source} muted className='media-video' /> :
              <img src={file.source} alt={file.source} className='media-image' />}
            {file.type === 'video' && <div className='video-overlay'><img src={playIcon} alt="playIcon" className='play-icon' /></div>}
          </button>
        ))}
      </div>
    </div>

  </>
}