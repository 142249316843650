import { all, call, put, takeLatest } from "redux-saga/effects";
import { galleryUIActions } from "./reducers";
import actions from './actions';
import { API, getDataCookie } from "common";
import Cookies from 'js-cookie';
import shopActions from "store/shop/actions";

const getPendingGallery = function* () {
  try {
    const res = yield call(API.get, 'gallery/all', { params: { status: 'PENDING' } });
    if (res.data.payload) {
      yield put(actions.getPendingGallery.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getPendingGallery.fail({}));
  }
}
const getStaffPendingGallery = function* () {
  const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
  try {
    const res = yield call(API.get, 'gallery/all', { params: { staffId, status: 'PENDING' } });
    if (res.data.payload) {
      yield put(actions.staff.getPendingGallery.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.staff.getPendingGallery.fail({}));
  }
}

const getApprovedGallery = function* () {
  try {
    const res = yield call(API.get, 'gallery/all', { params: { status: 'APPROVED' } });
    if (res.data.payload) {
      yield put(actions.getApprovedGallery.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.getApprovedGallery.fail({}));
  }
}

const getStaffApprovedGallery = function* () {
  const staffId = getDataCookie(process.env.REACT_APP_COOKIE_KEY);
  try {
    const res = yield call(API.get, 'gallery/all', { params: { staffId, status: 'APPROVED' } });
    if (res.data.payload) {
      yield put(actions.staff.getApprovedGallery.success(res.data.payload));
    }
  } catch (error) {
    yield put(actions.staff.getApprovedGallery.fail({}));
  }
}

const galleryInit = function* () {
  yield all([
    put(actions.getPendingGallery.fetch()),
    put(actions.getApprovedGallery.fetch()),
  ])
}

const staffGalleryInit = function* () {
  const staffPhone = yield Cookies.get(process.env.REACT_APP_COOKIE_KEY_PHONE);
  if (staffPhone) {
    yield put(shopActions.data.staffShopsByPhone.fetch(staffPhone));
  }
  yield all([
    put(actions.staff.getPendingGallery.fetch()),
    put(actions.staff.getApprovedGallery.fetch()),
  ])
}

export default function* galleryServiceSagas() {
  yield takeLatest(galleryUIActions.init, galleryInit);
  yield takeLatest(galleryUIActions.staffInit, staffGalleryInit);
  yield takeLatest(actions.getPendingGallery.fetch, getPendingGallery);
  yield takeLatest(actions.getApprovedGallery.fetch, getApprovedGallery);
  yield takeLatest(actions.staff.getPendingGallery.fetch, getStaffPendingGallery);
  yield takeLatest(actions.staff.getApprovedGallery.fetch, getStaffApprovedGallery);
}
