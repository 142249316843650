import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import constant from 'constant';
import { API, setHeader, setCookieAuthenticate, initHeader } from 'common';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import Cookies from 'js-cookie';
// reactstrap components
import {
	Button,
	FormGroup,
	Form,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	FormFeedback,
	Modal,
	ModalBody,
} from 'reactstrap';

export default withRouter(function SignIn({
	loginState,
	setLogin,
	history,
	location,
}) {
	const { handleSubmit, control, errors, setError } = useForm();
	const [phoneVerify, setPhoneVerify] = useState(false);
	const [phone, setPhone] = useState(null);
	const [openSalon, setOpenSalon] = useState(false);
	const [staffId, setStaffId] = useState(null);
	const [dataSalon, setDataSalon] = useState([]);
	const [salon, setSalon] = useState(null);
	const [ statusLogin, setStatusLogin ] = useState(0);
	const [disableSubmit, setDisableSubmit] = useState(false);

	const onSubmit = async (data) => {
		setDisableSubmit(true);
		switch (statusLogin) {
			case 0:
				try {
					let unmaskphone = data.phone.replace(/[^\d]/g, '');
					setPhone(unmaskphone);
					let sign_in_response = await API.get(`staff/sign-in-by-phone/${unmaskphone}`);
					if (sign_in_response.status === 200) {
						let { payload } = sign_in_response.data;
						if (payload.length > 0) {
							setSalon(payload[0]);
							setDataSalon(payload);
							setOpenSalon(true);
							setStatusLogin(1);
						}
						else {
							setError('phone', { type: 'error_phone' });
						}
					}
				} catch (error) {
					setError('phone', { type: 'error_phone' });
				}
				break;
			case 1:
				try {
					let response = await API.post('staff/generateOtp', {
							payload: { 
								phone: phone,
								shopId: salon.id
							},
						});
					// console.log(unmaskphone);
					if (response.status === 200) {
						let { payload } = response.data;
						setStaffId(payload.staffId);
						// open popup verify
						setPhoneVerify(!phoneVerify);
						// change status login: verify OTP
						setStatusLogin(2);
					}
					else {
						setError('salon', { type: 'get_otp_fail' });
					}

				} catch (error) {
				}
				break;
			case 2:
				try {
					let otp = data.otp;
					let response = await API.post('staff/verifyOtp', {
						payload: { 
							staffId: staffId, 
							verifyCode: otp,
							shopId: salon.id
						},
					});
					if (response.status === 200) {
						const { payload } = response.data;
						// console.log(payload);
						if (payload != 0) {
							setHeader(salon.apiKey);
							
							let staff = payload.staff;
							setCookieAuthenticate(
								staffId,
								parseInt(process.env.REACT_APP_EXPIRES_COOKIE)
							);
							// check is staff or manager
							var is_manager = 0;
							if (staff.positionName && staff.positionName === 'Admin') {
								is_manager = 1;
							}
							Cookies.set(process.env.REACT_APP_COOKIE_KEY_PHONE, phone, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
							Cookies.set(process.env.REACT_APP_COOKIE_KEY_ISMANAGER, is_manager, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
							initHeader();
							localStorage.setItem(
								'infor_staff',
								JSON.stringify(staff)
							);
							localStorage.setItem(
								'info_salon',
								JSON.stringify(salon)
							);
							
							window.location.href = is_manager ? 'overview' : '/';
						}
						else {
							setError('otp', { type: 'verify_fail' });
						}
					}
					else {
						setError('otp', { type: 'verify_fail' });
					}
				} catch (error) {
					console.log('error', error);
					const { data } = error.response;
					data.code === 1003 && setError('otp', { type: 'verify_fail' });
				}
				
				break;
		}
		setDisableSubmit(false);
	};

	const handleChange = (selectedOption) => {
		setSalon(selectedOption.value);
		setHeader(selectedOption.value.apiKey);
	}
	return (
		<>
			<Modal
				className="fixed-bottom m-0"
				size="lg"
				isOpen={loginState}
				toggle={() => setLogin(true)}
			>
				<ModalBody>
					<h2>Login</h2>
					<p style={{ color: '#2C2C2C' }}>
						Your information
					</p>
					<Form role="form" onSubmit={handleSubmit(onSubmit)}>
						<FormGroup id="form-phone-number">
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										<img
											src={require('assets/svg/ic_phone.svg')}
											alt="Phone Number"
										/>
									</InputGroupText>
								</InputGroupAddon>
								<Controller
									as={InputMask}
									control={control}
									mask={constant.MASK_PHONE_INPUT}
									className="form-control"
									style={{color: '#000'}}
									defaultValue=""
									type="tel"
									placeholder="Phone Number"
									name="phone"
									disabled={openSalon}
									rules={{
										required: true,
										pattern: /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/,
									}}
								/>
							</InputGroup>
							{errors.phone?.type === 'required' && (
								<FormFeedback>
									Your input is required
								</FormFeedback>
							)}
							{errors.phone?.type === 'pattern' && (
								<FormFeedback>
									Please enter the correct format
								</FormFeedback>
							)}
							{errors.phone?.type === 'error_phone' && (
								<FormFeedback>
									Your phone number does not exists.
								</FormFeedback>
							)}
						</FormGroup>
						{errors.account && (
							<FormFeedback>
								{errors.account.message}
							</FormFeedback>
						)}
						{openSalon ?
						(<FormGroup id="form-salon">
							<p style={{ color: '#2C2C2C' }}>
								Your salon
							</p>
							<Select
								value={{value: salon, label: salon.companyName}}
								onChange={handleChange}
								options={dataSalon.map((i) => ({
									value: i,
									label: i.companyName,
								}))}
								className="mb-4 input-group-alternative"
								isSearchable={false}
								placeholder="Salon"
								menuPlacement={'top'}
								styles={{
									control: (base) => ({
										...base,
										height: 46,
										minHeight: 46,
										border: '1px solid #adb5bd',
										color: '#adb5bd',
									}),
								}}
								name="salon"
								isDisabled={phoneVerify}
							/>
							{errors.salon?.type === 'get_otp_fail' && (
								<FormFeedback>
									Get OTP failed!
								</FormFeedback>
							)}
						</FormGroup>
						) : '' }
						{phoneVerify ?
						(<FormGroup id="form-verify">
							<InputGroup className="input-group-alternative">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
										{/* OTP */}
									</InputGroupText>
								</InputGroupAddon>
								<Controller
									as={InputMask}
									control={control}
									className="form-control"
									style={{color: '#000'}}
									defaultValue="1234"
									type="tel"
									placeholder="OTP"
									name="otp"
									rules={{
										required: true,
									}}
								/>
							</InputGroup>
							{errors.otp?.type === 'required' && (
								<FormFeedback>
									Your OTP is required
								</FormFeedback>
							)}
							{errors.otp?.type === 'verify_fail' && (
								<FormFeedback>
									Your verification code is incorrect
								</FormFeedback>
							)}
						</FormGroup>
						) : '' }
						<div className="text-center">
							<Button
								className="btn btn-block"
								color="primary"
								type="submit"
								disabled={disableSubmit}
							>
								{constant.BTN_LOGIN_LIST[statusLogin]}
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</>
	);
});
