import Page from 'components/Page';
import React, { useEffect, useRef, useState } from 'react'
import { Button, Row } from 'reactstrap';
import SalonInfoTabComponent from './Tab';
import PromotionBanner from './PromotionBanner';
import SalonBanner from './SalonBanner';
import SalonContact from './SalonContact';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import shopActions from 'store/shop/actions';
import shopSelectors from 'store/shop/selectors';
import { useForm } from 'react-hook-form';
import { formatPhone } from 'utils/formatPhone';
import { get, set } from 'lodash';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import { API, initHeader, setCookieAuthenticate, setHeader } from 'common';
import { toastRef } from 'components/Toast';
import Loader from 'components/Loader';
import ReactSelect from 'react-select';
import Cookies from 'js-cookie';
import ModalConfirm from 'widgets/ModalConfirm';

const compareChange = (newRecord, defaultRecord) => {
  const record = {};
  Object.entries(newRecord).map(([key, value]) => {
    const oldValue = get(defaultRecord, key);
    if (oldValue === value) return;
    set(record, key, value);
  });
  return record;
}

const SalonInformationPage = () => {
  const dispatch = useAppDispatch();
  const [visibleReview, setVisibleReview] = useState(false);
  const [visibleBannerPromotion, setVisibleBannerPromotion] = useState(false);
  const [visibleContact, setVisibleContact] = useState(false);
  const [banner, setBanner] = React.useState(null);
  const [promotionBanners, setPromotionBanners] = useState([]);
  const shopLoading = useAppSelector(shopSelectors.shopLoading);
  const shopInfo = useAppSelector(shopSelectors.shopInfo);
  const modalConfirm = useRef(null);
  const shops = useAppSelector(shopSelectors.staffShopsByPhone);
  const currentSalon = useAppSelector(shopSelectors.currentSalon);

  const form = useForm();
  const [loading, setLoading] = useState(false);

  const onChooseSalon = async (select) => {
    const salon = select.value;
    if (currentSalon.id === salon.id) return;
    const staffPhone = Cookies.get(process.env.REACT_APP_COOKIE_KEY_PHONE);
    if (!salon.id || !staffPhone) return;
    if (!modalConfirm.current) return;
    setLoading(true);
    try {
      const response = await API.post('staff/generateOtp', {
        payload: {
          phone: staffPhone,
          shopId: salon.id
        },
      });
      const staffId = response.data.payload.staffId;
      if (staffId) {
        modalConfirm.current.open({
          title: 'Switch Salon',
          message: 'Are you want to switch to ' + salon.companyName + ' ?',
        }, async () => {
          setLoading(true);
          try {
            let response = await API.post('staff/verifyOtp', {
              payload: {
                staffId: staffId,
                verifyCode: '1234',
                shopId: salon.id
              },
            });
            if (response.status === 200) {
              const { payload } = response.data;
              const salon = payload.shop;
              const staff = payload.staff;
              if (payload != 0) {
                setHeader(salon.apiKey);

                setCookieAuthenticate(
                  staff.id,
                  parseInt(process.env.REACT_APP_EXPIRES_COOKIE)
                );
                // check is staff or manager
                var is_manager = 0;
                if (staff.positionName && staff.positionName === 'Admin') {
                  is_manager = 1;
                }
                Cookies.set(process.env.REACT_APP_COOKIE_KEY_PHONE, staffPhone, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
                Cookies.set(process.env.REACT_APP_COOKIE_KEY_ISMANAGER, is_manager, { expires: parseInt(process.env.REACT_APP_EXPIRES_COOKIE) });
                initHeader();
                localStorage.setItem(
                  'infor_staff',
                  JSON.stringify(staff)
                );
                localStorage.setItem(
                  'info_salon',
                  JSON.stringify(salon)
                );

                window.location.href = is_manager ? 'overview' : '/';
              }
            }
          } catch (error) {
            if (toastRef.current) toastRef.current.show(error?.response?.data?.message || 'An error occurred, please try again!');
          } finally {
            setLoading(false);
          }
        })
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch(shopActions.data.shopInfo.fetch());
    dispatch(shopActions.data.staffShopsByPhone.fetch(Cookies.get(process.env.REACT_APP_COOKIE_KEY_PHONE)));
  }, []);

  useEffect(() => {
    if (!shopInfo) return;
    setBanner({
      id: 'banner-exist',
      src: shopInfo?.bannerUrl || '',
    });
    form.setValue('shopId', shopInfo?.id || '');
    form.setValue('companyName', shopInfo?.companyName || '');
    form.setValue('facebookUrl', shopInfo?.facebookUrl || '');
    form.setValue('instagramUrl', shopInfo?.instagramUrl || '');
    form.setValue('googleUrl', shopInfo?.googleUrl || '');
    form.setValue('yelpUrl', shopInfo?.yelpUrl || '');
    form.setValue('address', shopInfo?.address || '');
    form.setValue('city', shopInfo?.city || '');
    form.setValue('state', shopInfo?.state || '');
    form.setValue('zip', shopInfo?.zip || '');


    setVisibleBannerPromotion(!!shopInfo.showPromotion);

  }, [shopInfo]);

  const onSubmit = async (values) => {
    const body = compareChange(values, {
      facebookUrl: shopInfo?.facebookUrl || '',
      instagramUrl: shopInfo?.instagramUrl || '',
      googleUrl: shopInfo?.googleUrl || '',
      yelpUrl: shopInfo?.yelpUrl || '',
      address: shopInfo?.address || '',
      city: shopInfo?.city || '',
      state: shopInfo?.state || '',
      zip: shopInfo?.zip || '',
    });

    if (banner?.id && banner.id !== 'banner-exist') {
      set(body, 'bannerBase64', removeBase64Prefix(banner.src));
    }

    if (promotionBanners?.length > 0) {
      set(body, 'base64Promotion', promotionBanners.map(o => removeBase64Prefix(o.src)));
    }
    if (values.shopId) {
      set(body, 'shopId', +values.shopId);
    }

    set(body, 'showPromotion', visibleBannerPromotion);
    setLoading(true);
    try {
      const res = await API.put('shop/update', { payload: body });
      console.log(res.data.payload);
      if (res.data.payload) {
        if (toastRef.current) {
          toastRef.current.success('Success');
          dispatch(shopActions.data.shopInfo.fetch());
        }
      }
    } catch (error) {
      if (toastRef.current) toastRef.current.show(error?.response?.data?.message || 'An error occurred, please try again!');
    } finally {
      setLoading(false);
    }
  };

  const StickFooter = () => (
    <Row style={{ padding: '4px 15px', background: '#f0f0f0c4', paddingBottom: '8px' }}>
      <div style={{ margin: '0 15px', width: '100%', display: 'flex', gap: 8 }}>
        <Button onClick={form.handleSubmit(onSubmit)} className="w-100" color="primary" style={{ fontSize: 14, margin: 0, padding: '0.625rem 0rem' }}>Update</Button>
      </div>
    </Row>
  );
  return (
    <>
      {shopLoading || loading && <Loader />}
      <Page StickFooter={StickFooter} title={'Salon Information'} containerClass={'salon-information-content-kit'}>
        <div className="salon-switch-container">
          <div className="label-updated">Your Salons:</div>
          <ReactSelect
            value={currentSalon ? { value: currentSalon, label: currentSalon.companyName } : null}
            onChange={onChooseSalon}
            options={shops.map((i) => ({
              value: i,
              label: i.companyName,
            }))}
            className="input-group-alternative"
            isSearchable={false}
            placeholder="Salon"
            menuPlacement={'bottom'}
            styles={{
              control: (base) => ({
                ...base,
                height: 46,
                minHeight: 46,
                border: '1px solid #adb5bd',
                color: '#adb5bd',
              }),
            }}
            name="salon"
          />
        </div>
        <div className="salon-information-content-container">
          <SalonInfoTabComponent title='Salon’s Information' defaultExpand className='salon-information-section'>
            <SalonBanner file={banner} setFile={setBanner} />
            <div className="salon-information-input-container">
              <span className="label-input">Salon’s Name</span>
              <input disabled type="text" value={shopInfo?.companyName || ''} />
            </div>
            <div className="salon-information-input-container">
              <span className="label-input">Email</span>
              <input disabled type="text" value={shopInfo?.email || ''} />
            </div>
            <div className="salon-information-input-container">
              <span className="label-input">Phone</span>
              <input disabled type="text" value={formatPhone(shopInfo?.phone || '')} />
            </div>
          </SalonInfoTabComponent>
          {/* <SalonInfoTabComponent visible={visibleReview} setVisible={setVisibleReview} title='Enable Google Reviews' enableVisible disabledArrow /> */}
          <SalonInfoTabComponent
            className='promotion-banner-section'
            defaultExpand
            visible={visibleBannerPromotion} setVisible={setVisibleBannerPromotion}
            title='Show Banner Promotion'
            enableVisible
          >
            <PromotionBanner data={promotionBanners} setData={setPromotionBanners} />
          </SalonInfoTabComponent>
          <SalonInfoTabComponent defaultExpand className='salon-contact-section' visible={visibleContact} setVisible={setVisibleContact} title='Contact'>
            <SalonContact form={form} />
          </SalonInfoTabComponent>
        </div>
        <ModalConfirm ref={modalConfirm} />
      </Page>
    </>
  )
}

export default SalonInformationPage;
